import * as Sentry from '@sentry/nextjs';

const SAMPLE_RATE = process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE || 1.0;

export const sentryCommonConfig: Parameters<typeof Sentry.init>['0'] = {
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: Number(SAMPLE_RATE),
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT || 'dev'
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
};
